import { FC } from "react";
import "./styles.scss";
import { IconName } from "./iconNames";

type Props = {
  name: IconName;
  weight?: number | string;
  filled?: boolean;
  size?: number | string;
  color?: string;
  className?: string;
  onClick?: () => void | null;
};
const AEIcon: FC<Props> = ({
  name,
  weight,
  filled,
  size,
  className,
  color,
  onClick
}) => {
  const isFilled = Number(filled);
  return (
    <span
      className={`material-symbols-outlined ${className}`}
      data-testid={`test-${name}`}
      style={{
        fontVariationSettings: `"wght" ${weight || 400}, "FILL" ${isFilled}`,
        fontSize: size || "1rem",
        color: color || ""
      }}
      onClick={onClick && onClick}
    >
      {name}
    </span>
  );
};

export default AEIcon;
