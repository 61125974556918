import { DateTime, Interval } from "luxon";
import {
  DateRange,
  DateType,
  RelativeLabel,
  RelativeRange
} from "../types/date";

function queryParamsToFlaskDateRange(filterParams: string[]): string[] {
  if (filterParams.length === 1) {
    const { startTs, endTs } = filterParamsToQuery(filterParams[0]);
    return [String(startTs), String(endTs)];
  } else {
    return filterParams;
  }
}

function filterParamsToQuery(
  filterParams: string
): { startTs: number; endTs: number } {
  const now = DateTime.local();
  const startOfDay = now.startOf("day");
  const endOfDay = now.endOf("day");
  const endTs = Math.floor(endOfDay.toSeconds());
  let startTs = Math.floor(startOfDay.minus({ weeks: 2 }).toSeconds());

  if (filterParams === RelativeRange.THREE_DAYS) {
    startTs = Math.floor(startOfDay.minus({ days: 3 }).toSeconds());
  } else if (filterParams === RelativeRange.ONE_WEEK) {
    startTs = Math.floor(startOfDay.minus({ weeks: 1 }).toSeconds());
  } else if (filterParams === RelativeRange.TWO_WEEKS) {
    startTs = Math.floor(startOfDay.minus({ weeks: 2 }).toSeconds());
  } else if (filterParams === RelativeRange.ONE_MONTH) {
    startTs = Math.floor(startOfDay.minus({ months: 1 }).toSeconds());
  } else if (filterParams === RelativeRange.THREE_MONTHS) {
    startTs = Math.floor(startOfDay.minus({ months: 3 }).toSeconds());
  } else if (filterParams.includes("-")) {
    const seconds = filterParams.split("-", 2);
    const start = parseInt(seconds[0]);
    const end = parseInt(seconds[1]);
    if (!isNaN(start) || !isNaN(end)) {
      return {
        startTs: start,
        endTs: end
      };
    }
  }

  return { startTs, endTs };
}

function queryParamsToDateRange(
  filterParams?: string[]
): DateRange | undefined {
  if (!filterParams) {
    return;
  }

  if (filterParams.length === 1) {
    const maybeRelative = filterParams[0];
    if (maybeRelative === RelativeRange.THREE_DAYS) {
      return { type: DateType.RELATIVE, value: RelativeRange.THREE_DAYS };
    } else if (maybeRelative === RelativeRange.ONE_WEEK) {
      return { type: DateType.RELATIVE, value: RelativeRange.ONE_WEEK };
    } else if (maybeRelative === RelativeRange.TWO_WEEKS) {
      return { type: DateType.RELATIVE, value: RelativeRange.TWO_WEEKS };
    } else if (maybeRelative === RelativeRange.ONE_MONTH) {
      return { type: DateType.RELATIVE, value: RelativeRange.ONE_MONTH };
    } else if (maybeRelative === RelativeRange.THREE_MONTHS) {
      return { type: DateType.RELATIVE, value: RelativeRange.THREE_MONTHS };
    }
  } else if (filterParams.length === 2) {
    const start = parseInt(filterParams[0]);
    const end = parseInt(filterParams[1]);
    if (!isNaN(start) || !isNaN(end)) {
      return {
        type: DateType.ABSOLUTE,
        value: Interval.fromDateTimes(
          DateTime.fromSeconds(start),
          DateTime.fromSeconds(end)
        )
      };
    }
  }
}

function dateRangeToQueryParams(dateRange: DateRange): string[] {
  if (dateRange.type === DateType.ABSOLUTE) {
    if (!dateRange.value) {
      return [];
    }
    return [
      Math.floor(dateRange.value.start.toSeconds()).toString(),
      Math.floor(dateRange.value.end.toSeconds()).toString()
    ];
  } else {
    return [dateRange.value];
  }
}

function dateRangeToLabel(
  dateRange?: DateRange,
  defaultLabel = "All time"
): string {
  if (!dateRange) {
    return defaultLabel;
  }

  if (dateRange.type === DateType.ABSOLUTE && dateRange.value) {
    return dateRange.value.toFormat("MM/dd/yyyy", { separator: " - " });
  } else {
    switch (dateRange.value) {
      case RelativeRange.THREE_DAYS:
        return RelativeLabel.THREE_DAYS;
      case RelativeRange.ONE_WEEK:
        return RelativeLabel.ONE_WEEK;
      case RelativeRange.TWO_WEEKS:
        return RelativeLabel.TWO_WEEKS;
      case RelativeRange.ONE_MONTH:
        return RelativeLabel.ONE_MONTH;
      case RelativeRange.THREE_MONTHS:
        return RelativeLabel.THREE_MONTHS;
      default:
        return defaultLabel;
    }
  }
}

function relativeRangeToInterval(relative: RelativeRange): Interval {
  const { startTs, endTs } = filterParamsToQuery(relative);
  return Interval.fromDateTimes(
    DateTime.fromSeconds(startTs),
    DateTime.fromSeconds(endTs)
  );
}

const getTimeDiffInDays = (timestamp: number | undefined): number => {
  if (!timestamp || isNaN(timestamp)) return 0;
  return Math.floor(
    DateTime.now().diff(DateTime.fromSeconds(timestamp), "days").days
  );
};

const getTimeDiffInDaysDateStr = (dateStr: string | undefined): number => {
  if (!dateStr) return 0;
  const dt = DateTime.fromJSDate(new Date(dateStr));
  return Math.floor(
    DateTime.now().diff(DateTime.fromSeconds(dt.toSeconds()), "days").days
  );
};

export {
  filterParamsToQuery,
  queryParamsToFlaskDateRange,
  queryParamsToDateRange,
  dateRangeToQueryParams,
  dateRangeToLabel,
  relativeRangeToInterval,
  getTimeDiffInDays,
  getTimeDiffInDaysDateStr
};
