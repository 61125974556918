import { FullWidthButton } from "@aptedge/lib-support-ui/src/features/TicketInsight/FullWidthButton/FullWidthButton";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import {
  ArticleCreationState,
  ArticleStatus,
  GeneratedKnowledgeResult,
  ITicketInfo
} from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { Tooltip } from "@mui/material";
import { FC } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { WebCacheKey } from "../../clients/cache";
import { fetchArticleByTicketId } from "../../clients/GeneratedKnowledge/fetchArticleByTicketId";
import { Routes } from "../../routes/routes";
import styles from "./CreateArticleButton.module.scss";

type CreateArticleButtonProps = {
  ticket: ITicketInfo | undefined;
};

const triggerEvent = (ticketId: string): void => {
  dataLayerPush({
    event: GTM_EVENTS.GTM_CREATE_KB_GEN_ARTICLE,
    data: {
      ticket_id: ticketId
    }
  });
};

const CreateArticleButton: FC<CreateArticleButtonProps> = ({ ticket }) => {
  const { flags } = useFlags();
  const history = useHistory();

  const articleQuery = useQuery(
    [WebCacheKey.TICKET_ID, ticket?.id],
    () => fetchArticleByTicketId({ ticketId: ticket?.id }),
    { enabled: Boolean(ticket && ticket.id) }
  );

  const {
    data: ticketArticlesData,
    isSuccess: ticketArticlesIsSuccess,
    isLoading: ticketArticlesDataLoading,
    refetch
  } = articleQuery;

  const refetchArticles = async (): Promise<
    GeneratedKnowledgeResult[] | undefined
  > => {
    const result = await refetch();
    return result.data?.items;
  };

  const handleCreateArticlesClick = (): void => {
    triggerEvent(String(ticket?.id));
    if (ticketIsClosed) {
      history.push(
        `${Routes.ARTICLES_REVIEW}?ticketId=${ticket?.id}&articleCreationState=${ArticleCreationState.GENERATION}&articleStatus=${ArticleStatus.DRAFT}`
      );
    }
  };

  const handleOpenInWebApp = (
    updatedArticles: GeneratedKnowledgeResult[] | undefined
  ): void => {
    if (updatedArticles) {
      if (flags.kbGenPublishProcess) {
        history.push(`${Routes.ARTICLES}?articleId=${updatedArticles[0].id}`);
      } else {
        const publishedArticle = updatedArticles.filter(
          (article) => article.status === ArticleStatus.PUBLISHED
        );
        if (publishedArticle.length > 0) {
          history.push(
            `${Routes.ARTICLES}?articleId=${publishedArticle[0].id}`
          );
        } else {
          history.push(
            `${Routes.ARTICLES_REVIEW}?ticketId=${ticket?.id}&articleCreationState=${ArticleCreationState.GENERATED}&articleStatus=${ArticleStatus.DRAFT}`
          );
        }
      }
    }
  };

  const handleNavigation = async (): Promise<void> => {
    const updatedArticles = await refetchArticles();
    if (Boolean(updatedArticles && updatedArticles.length > 0)) {
      handleOpenInWebApp(updatedArticles);
    } else {
    }
  };

  const onArticleCreateBtnClicked = (): void => {
    if (isArticleCreated) handleNavigation();
    else handleCreateArticlesClick();
  };

  const ticketStatus = ticket?.status ?? "";
  const ticketIsClosed = flags.insightsClosedTicketStatuses.includes(
    ticketStatus
  );
  const isLoading = !ticketStatus || ticketArticlesDataLoading;

  const toolTipTitle = ticketIsClosed
    ? ""
    : "Please resolve this ticket to generate linked articles";

  const ticketHasGeneratedKnowledge = Boolean(
    ticketArticlesData && ticketArticlesData.items.length > 0
  );

  const isArticleCreated =
    flags.insightsKBDraft &&
    ticketArticlesIsSuccess &&
    ticketHasGeneratedKnowledge &&
    ticketIsClosed;

  const darkModeButton = !isLoading && ticketIsClosed && !isArticleCreated;

  return (
    <Tooltip placement="top-start" title={toolTipTitle}>
      <span>
        <FullWidthButton
          variant="contained"
          startIcon={<AutoStoriesIcon />}
          endIcon={<ArrowForwardIcon className={styles.forwardIcon} />}
          mode={darkModeButton ? "dark" : "light"}
          disabled={!ticketIsClosed}
          onClick={onArticleCreateBtnClicked}
          data-testid="article-create-view-button"
        >
          {isLoading
            ? ""
            : isArticleCreated
            ? "View article"
            : "Create article"}
        </FullWidthButton>
      </span>
    </Tooltip>
  );
};

export default CreateArticleButton;
