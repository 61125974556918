import { ResourceLink } from "@aptedge/lib-ui/src/types/selfService";
import { ChangeEvent, FC, useState } from "react";
import styles from "../styles.module.scss";

type Props = {
  onAddNewResourceItem: (resourceItem: ResourceLink) => void;
  selectedResource?: ResourceLink;
};
export const ResourceInput: FC<Props> = ({
  onAddNewResourceItem,
  selectedResource
}) => {
  const [resourceItem, setResourceItem] = useState<ResourceLink>(
    selectedResource || ({} as ResourceLink)
  );
  const saveResourceInput = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target as HTMLInputElement;

    setResourceItem((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const onSaveBtnClick = (): void => {
    if (!resourceItem) return;
    onAddNewResourceItem(resourceItem);
  };
  return (
    <div>
      <table>
        <tr>
          <td>
            <label htmlFor="title">Title</label>
          </td>
          <td>
            <input
              type="text"
              placeholder="Example"
              id="title"
              name="title"
              value={resourceItem?.title || ""}
              className={styles.resourceInput}
              onChange={saveResourceInput}
            />
          </td>
        </tr>
        <tr>
          <td>
            <label htmlFor="url">URL</label>
          </td>
          <td>
            <input
              type="text"
              id="url"
              name="url"
              value={resourceItem?.url || ""}
              placeholder="https://www.example.com"
              className={styles.resourceInput}
              onChange={saveResourceInput}
            />
          </td>
        </tr>
        <tr>
          <td>
            <label htmlFor="desc">Description</label>
          </td>
          <td>
            <input
              type="text"
              id="desc"
              name="description"
              placeholder="This is an example resource"
              value={resourceItem?.description || ""}
              className={styles.resourceInput}
              onChange={saveResourceInput}
            />
          </td>
        </tr>
      </table>
      <button onClick={onSaveBtnClick}>Save</button>
    </div>
  );
};
