import {
  SEARCH_RESULT_TYPE,
  ITicketInfo
} from "@aptedge/lib-ui/src/types/entities";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchTicketById } from "../../clients/Tickets/fetchTicketById";
import { updateTicket } from "../../clients/Tickets/updateTicket";

function useTicketQuery(
  isKCWebApp: boolean,
  ticketId?: number | string,
  sourceType?: string,
  isLoading?: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callbackFn?: (result: any, type: string | undefined) => any
): UseQueryResult<ITicketInfo> {
  return useQuery(
    [WebCacheKey.TICKET, ticketId],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => fetchTicketById(ticketId!, isKCWebApp),
    {
      enabled:
        !!ticketId && sourceType === SEARCH_RESULT_TYPE.TICKET && !isLoading,
      select: (data) => {
        if (typeof callbackFn === "function" && sourceType) {
          return callbackFn(data, sourceType);
        } else {
          return data;
        }
      }
    }
  );
}

type Variables = Parameters<typeof updateTicket>[0];

function useTicketMutation(
  options?: UseMutationOptions<ITicketInfo, Error, Variables, void>
): UseMutationResult<ITicketInfo, Error, Variables, void> {
  const queryClient = useQueryClient();

  return useMutation(updateTicket, {
    onMutate: options?.onMutate,
    onSuccess: (ticket, variables, context) => {
      queryClient.setQueryData([WebCacheKey.TICKET, ticket.id], ticket);
      if (options?.onSuccess) options.onSuccess(ticket, variables, context);
    },
    onError: options?.onError,
    onSettled: options?.onSettled
  });
}

export { useTicketQuery, useTicketMutation };
