import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { FC } from "react";
import { GeneralSettings } from "./Sections/GeneralSettings/GeneralSettings";
import { InternalConfig } from "./Sections/InternalConfig/InternalConfig";
import { KnowledgeSources } from "./Sections/KnowledgeSources/KnowledgeSources";
import Limits from "./Sections/Limits/Limits";
import ResourceSettings from "./Sections/ResourceSettings/ResourceSettings";
import { SaveConfig } from "./Sections/SaveConfig/SaveConfig";
import Integration from "./Sections/Support/Integration";
import { useCSSConfig } from "./utils/useCSSConfig";

const CSSConfiguration: FC = () => {
  const {
    config,
    originalConfig,
    handleConfigChange,
    saveCSSConfig,
    isSaved,
    isLoading,
    handleAddNewPageGlob,
    removePageGlob,
    isError,
    refetchConfig
  } = useCSSConfig();
  const { flags } = useFlags();

  return (
    <div>
      {flags.aptAnswersCssFiltersUi && <KnowledgeSources />}
      <GeneralSettings
        config={config}
        handleConfigChange={handleConfigChange}
        isLoading={isLoading}
        handleAddNewPageGlob={handleAddNewPageGlob}
        removePageGlob={removePageGlob}
      />
      <ResourceSettings
        config={config}
        isLoading={isLoading}
        refetchConfig={refetchConfig}
        handleConfigChange={handleConfigChange}
      />
      <Limits
        config={config}
        originalConfig={originalConfig}
        handleConfigChange={handleConfigChange}
        isLoading={isLoading}
      />
      {flags.cssInternalConfig && (
        <InternalConfig
          config={config}
          handleConfigChange={handleConfigChange}
          divider={false}
          isLoading={isLoading}
        />
      )}
      <Integration />
      <SaveConfig
        saveConfig={saveCSSConfig}
        isSaved={isSaved}
        isLoading={isLoading}
        isError={isError}
      />
    </div>
  );
};

export default CSSConfiguration;
