import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { InsightTicketSummaryResponse } from "../../types/entities";

type TicketSummary = string | null;
type KbDraftContent = string | null;
type AutoSearchQuery = string | null;

type SliceStateType = {
  ticketSummaryEnabled: boolean;
  ticketSummary: TicketSummary;
  insightTicketSummary: InsightTicketSummaryResponse | null;
  kbDraftContent: KbDraftContent;
  autoSearchQuery: AutoSearchQuery;
  autoSearchSuccess: boolean;
};

const initialState: SliceStateType = {
  ticketSummaryEnabled: false,
  ticketSummary: null,
  insightTicketSummary: null,
  kbDraftContent: null,
  autoSearchQuery: null,
  autoSearchSuccess: false
};

const ticketInsightsSlice = createSlice({
  name: "ticketInsights",
  initialState,
  reducers: {
    setTicketSummaryEnabled: (state, action: PayloadAction<boolean>) => {
      state.ticketSummaryEnabled = action.payload;
    },
    setTicketSummary: (state, action: PayloadAction<TicketSummary>) => {
      state.ticketSummary = action.payload;
    },
    setTicketInsightSummary: (
      state,
      action: PayloadAction<InsightTicketSummaryResponse>
    ) => {
      state.insightTicketSummary = action.payload;
    },
    setKbDraftContent: (state, action: PayloadAction<KbDraftContent>) => {
      state.kbDraftContent = action.payload;
    },
    setAutoSearchQuery: (state, action: PayloadAction<AutoSearchQuery>) => {
      state.autoSearchQuery = action.payload;
    },
    setAutoSearchSuccess: (state, action: PayloadAction<boolean>) => {
      state.autoSearchSuccess = action.payload;
    }
  }
});

export const {
  setTicketSummaryEnabled,
  setTicketSummary,
  setKbDraftContent,
  setTicketInsightSummary,
  setAutoSearchQuery,
  setAutoSearchSuccess
} = ticketInsightsSlice.actions;

export default ticketInsightsSlice.reducer;
