const WebCacheKey = {
  EDGE: "edge",
  EDGE_LISTING: "edges",
  EDGE_SEARCH: "edge_search",
  EDGE_TICKETS: "edge_tickets",
  EDGE_FILES: "edge_files",
  EDGES_BY_ID: "edges_by_id",
  TEAM: "team",
  TEAMS: "teams",
  TEAMS_BY_ID: "teams_by_id",
  TICKET: "ticket",
  TICKET_ID: "ticket_id",
  TICKETS: "tickets",
  TICKET_SEARCH: "ticket_search",
  LABELS_SEARCH: "labels_search",
  JIRAS: "jiras",
  JIRAS_BY_ID: "jiras_by_id",
  CUSTOM_FIELD_MAPPING_CONFIG: "custom_field_mapping_config",
  CREATED_TICKETS: "created_tickets",
  CREATED_TICKETS_TIME_SERIES: "created_tickets_time_series",
  CREATED_TICKETS_SUMMARY: "created_tickets_summary",
  CREATED_TICKETS_CATEGORIES: "created_tickets_categories",
  OPEN_TICKETS: "open_tickets",
  OPEN_TICKETS_TIME_SERIES: "open_tickets_time_series",
  OPEN_TICKETS_SUMMARY: "open_tickets_summary",
  OPEN_TICKETS_CATEGORIES: "open_tickets_categories",
  TEMPLATES: "templates",
  NOTIFICATIONS: "notifications",
  NOTIFICATION_COUNT: "notification_count",
  AUDIT_LOGS_INFINITE: "audit_logs_infinite",
  AUDIT_LOGS: "audit_logs",
  KNOWLEDGE_BASE_EXCLUSIONS: "knowledge_base_exclusions",
  KNOWLEDGE_BASE_URLS: "knowledge_base_urls",
  AGENT_EMAIL_DOMAINS: "agent_email_domains",
  FEDERATED_SEARCH_URL_PREFIXES: "federated_search_url_prefixes",
  PASSWORD_COMPLEXITY_CONFIG: "password_complexity_config",
  TICKET_SUMMARY_ALERT: "ticket_summary_alert",
  BUSINESS_DATA_SOURCE: "business_data_source",
  INTERNAL_ISSUE_JIRA_CLOUD_SOURCE: "internal_issue_jira_cloud_source",
  INTERNAL_ISSUE_JIRA_ON_PREMISE_SOURCE:
    "internal_issue_jira_on_premise_source",
  SHAREPOINT_SOURCE: "sharepoint_source",
  SHAREPOINT_DRIVE_SOURCE: "sharepoint_drive_source",
  CONFLUENCE_CLOUD_ARTICLE_SOURCE: "confluence_cloud_article_source",
  CONFLUENCE_ON_PREM_ARTICLE_SOURCE: "confluence_on_prem_article_source",
  TICKET_DATA_SOURCE: "ticket_data_source",
  TICKET_PRIORITIES: "ticket_priorities",
  TICKET_STATUSES: "ticket_statuses",
  TICKET_TYPES: "ticket_types",
  TICKET_REASONS: "ticket_reasons",
  TICKET_USERS: "ticket_users",
  USERS: "users",
  NOTIFICATION_CONFIG: "notification_config",
  RECOMMENDED_EDGES: "recommended_edges",
  RECOMMENDED_TICKETS: "recommended_tickets",
  ANALYTICS_PAGE_SEARCH: "analytics_page_search",
  CLASSIFICATION_METRICS: "classification_metrics",
  EMERGING_TRENDS_SUMMARIES: "emerging_trends_summaries",
  EMERGING_TRENDS_TIME_SERIES: "emerging_trends_time_series",
  CUSTOMER_IMPACTS: "customer_impacts",
  LINKED_ISSUE_COUNT: "linked_issue_count",
  IMPACT: "impact",
  IMPACT_BY_THEME: "impact_by_theme",
  IMPACT_BY_PRODUCT: "impact_by_product",
  IMPACT_BY_FEATURE: "impact_by_feature",
  IMPACT_BY_CUSTOMER: "impact_by_customer",
  IMPACT_TICKET_COUNT: "impact_ticket_count",
  IMPACT_TIME_SERIES: "impact_time_series",
  LINKED_PRODUCT_ISSUES: "linked_product_issues",
  CUSTOMERS_LIST: "customer_list",
  PRODUCT: "product",
  PRODUCTS: "products",
  PRODUCTS_BY_ID: "products_by_id",
  FEATURE: "feature",
  FEATURES_BY_ID: "features_by_id",
  CUSTOMER: "customer",
  CUSTOMERS_BY_ID: "customers_by_id",
  THEME: "theme",
  THEMES: "themes",
  THEMES_BY_ID: "themes_by_id",
  SAVED_QUERIES: "saved_queries",
  SAVED_QUERY: "saved_query",
  BULK_REPLIES: "bulk_replies",
  JOB: "job",
  COMPOSITE_SEARCH: "composite_search",
  COMPOSITE_SEARCH_PREVIEW: "composite_search_preview",
  SPACE: "space",
  SPACES: "spaces",
  SLACK_SOCIAL_SOURCE: "slack_social_source",
  ZENDESK_KB_SOURCE: "zendesk_kb_source",
  SALESFORCE_KNOWLEDGE_SOURCE: "salesforce_knowledge_source",
  SNOW_KNOWLEDGE_SOURCE: "snow_knowledge_source",
  SEARCH_SUGGESTIONS: "search_suggestions",
  AZURE_DEVOPS_CLOUD: "azure_devops_cloud",
  NOTION_ARTICLE: "notion_article",
  BATCH_USER_LIST: "batch_user_list",
  USER_LIST: "user_list",
  ONELOGIN_SOURCE: "onelogin_source",
  ONELOGIN_URL: "onelogin_url",
  MS_TEAMS: "ms_teams",
  DYNAMIC_ARTICLE_SOURCE: "dynamic_article_source",
  DYNAMIC_INTEGRATION_SETTINGS: "dynamic_integration_settings",
  SOCIAL_THREAD: "social_thread",
  GENERATED_KNOWLEDGE_PUBLISHED: "generated_knowledge_published",
  GENERATED_KNOWLEDGE_UNPUBLISHED: "generated_knowledge_unpublished",
  GENERATED_KNOWLEDGE_ARTICLE: "generated_knowledge_article",
  APT_ANSWERS_FILTERS: "apt_answers_filters",
  APT_ANSWERS_INTEGRATIONS: "apt_answers_integrations",
  TICKET_KB_ARTICLE: "ticket_kb_article",
  ARTICLE: "article",
  APT_ANSWERS_MY_PRODUCTS: "apt_answers_my_products"
} as const;

export { WebCacheKey };
