import { createSlice } from "@reduxjs/toolkit";
import { ConfigResponse } from "../../types/selfService";

type SliceStateType = {
  cssConfig: ConfigResponse | null;
};

const initialState: SliceStateType = {
  cssConfig: {} as ConfigResponse
};

const selfServiceSlice = createSlice({
  name: "selfService",
  initialState,
  reducers: {
    updateCssConfig: (state, action) => {
      state.cssConfig = action.payload;
    }
  }
});

export const { updateCssConfig } = selfServiceSlice.actions;

export default selfServiceSlice.reducer;
