import classNames from "classnames";
import camelCase from "lodash/camelCase";
import * as aeLogoSmall from "./icons/aeLogoSmall.svg";
import * as aiAddFilter from "./icons/aiAddFilter.svg";
import * as aiAnswer from "./icons/aiAnswer.svg";
import * as aiAnswerPreview from "./icons/aiAnswerPreview.svg";
import * as aiAnswerReload from "./icons/aiAnswerReload.svg";
import * as aiBot from "./icons/aibot.svg";
import * as aiBotWithoutCircle from "./icons/aiBotWithoutCircle.svg";
import * as aiExpand from "./icons/aiExpand.svg";
import * as aiPeople from "./icons/aiPeople.svg";
import * as aiSetting from "./icons/aiSetting.svg";
import * as articleIcon from "./icons/articleIcon.svg";
import * as autoLink from "./icons/autoLink.svg";
import * as autoReply from "./icons/autoReply.svg";
import * as azureDevops from "./icons/azureDevops.svg";
import * as building from "./icons/building.svg";
import * as checked from "./icons/checked.svg";
import * as checkedSquare from "./icons/checkedSquare.svg";
import * as chevronDown from "./icons/chevronDown.svg";
import * as closeIcon from "./icons/closeIcon.svg";
import * as confluence from "./icons/confluence.svg";
import * as copy from "./icons/copy.svg";
import * as createdOn from "./icons/createdOn.svg";
import * as customer from "./icons/customer.svg";
import * as cxOne from "./icons/cxOne.svg";
import * as deleteIcon from "./icons/deleteIcon.svg";
import * as doc360 from "./icons/doc360.svg";
import * as docIcon from "./icons/docIcon.svg";
import * as docusaurus from "./icons/docusaurus.svg";
import * as edge from "./icons/edge.svg";
import * as edgeSR from "./icons/edgeIcon.svg";
import * as edgesIcon from "./icons/edgesIcon.svg";
import * as editIcon from "./icons/editIcon.svg";
import * as error from "./icons/error.svg";
import * as generatedKnowledgeBlue from "./icons/generatedKnowledgeBlue.svg";
import * as generatedKnowledgeBlueLarge from "./icons/generatedKnowledgeBlueLarge.svg";
import * as generatedKnowledgeGrayLarge from "./icons/generatedKnowledgeGrayLarge.svg";
import * as globe from "./icons/globe.svg";
import * as googleIcon from "./icons/google.svg";
import * as greenCheck from "./icons/greenCheck.svg";
import * as hiddenFilters from "./icons/hiddenFilters.svg";
import * as inbox from "./icons/inbox.svg";
import * as infoIcon from "./icons/infoIcon.svg";
import * as issue from "./icons/issue.svg";
import * as issues from "./icons/issues.svg";
import * as issuesIcon from "./icons/issuesIcon.svg";
import * as link from "./icons/link.svg";
import * as lock from "./icons/lock.svg";
import * as logoutLeftIG from "./icons/logoutLeftIG.svg";
import * as madCap from "./icons/madcap.svg";
import * as modelBot from "./icons/modelBot.svg";
import * as msIcon from "./icons/msIcon.svg";
import * as msTeamsIcon from "./icons/msTeamsIcon.svg";
import * as noRecentSearches from "./icons/noRecentSearches.svg";
import * as noResultFound from "./icons/noResultFound.svg";
import * as notion from "./icons/notionNew.svg";
import * as openInWeb from "./icons/openInWeb.svg";
import * as pdfIcon from "./icons/pdf-icon.svg";
import * as peaceHandIcon from "./icons/peace-hand.svg";
import * as plus from "./icons/plus.svg";
import * as preview from "./icons/preview.svg";
import * as productFilters from "./icons/productFilters.svg";
import * as productFiltersLarge from "./icons/productFiltersLarge.svg";
import * as productFiltersMenu from "./icons/productFiltersMenu.svg";
import * as productFiltersSelected from "./icons/productFiltersSelected.svg";
import * as productFiltersUnselected from "./icons/productFiltersUnselected.svg";
import * as quickFilters from "./icons/quickFilters.svg";
import * as quickFiltersLarge from "./icons/quickFiltersLarge.svg";
import * as quickFiltersMenu from "./icons/quickFiltersMenu.svg";
import * as quickFiltersUnselected from "./icons/quickFiltersUnselected.svg";
import * as refresh from "./icons/refresh.svg";
import * as reStart from "./icons/reStart.svg";
import * as salesforce from "./icons/salesforce.svg";
import * as searchSort from "./icons/searchSort.svg";
import * as sendIcon from "./icons/sendIcon.svg";
import * as negative from "./icons/sentimentIcons/negative.svg";
import * as neutral from "./icons/sentimentIcons/neutral.svg";
import * as positive from "./icons/sentimentIcons/positive.svg";
import * as sharepointIcon from "./icons/sharepointIcon.svg";
import * as slackIcon from "./icons/slackIcon.svg";
import * as snow from "./icons/snow.svg";
import * as socialIcon from "./icons/socialIcon.svg";
import * as sortCheck from "./icons/sortCheck.svg";
import * as submitFeedback from "./icons/submitFeedback.svg";
import * as thumbsDown from "./icons/thumbsDown.svg";
import * as thumbsDownSelected from "./icons/thumbsDownSelected.svg";
import * as thumbsUp from "./icons/thumbsUp.svg";
import * as thumbsUpSelected from "./icons/thumbsUpSelected.svg";
import * as ticket from "./icons/ticket.svg";
import * as tickeIcon from "./icons/ticketIcon.svg";
import * as tickets from "./icons/tickets.svg";
import * as trash from "./icons/trash.svg";
import * as uncheckedSquare from "./icons/uncheckedSquare.svg";
import * as updatedOn from "./icons/updatedOn.svg";
import * as user from "./icons/user.svg";
import * as userOutline from "./icons/userOutline.svg";
import * as watch from "./icons/watch.svg";
import * as zendesk from "./icons/zendesk.svg";

import "./Icon.scss";

type Props = React.HTMLAttributes<HTMLSpanElement>;

type WithIconComponent = React.FC<Props>;

const withIcon = (
  component: React.ComponentType,
  className: string
): WithIconComponent => {
  const WrappedComponent = component;

  const Icon: WithIconComponent = ({
    onClick,
    className: classNameProp,
    ...rest
  }) => (
    <span
      onClick={onClick}
      className={classNames("icon", className, classNameProp, {
        link: !!onClick
      })}
      data-testid={className}
      {...rest}
    >
      <WrappedComponent />
    </span>
  );

  Icon.displayName = `withIcon-${camelCase(
    component.displayName || className.split(" ").join()
  )}`;

  return Icon;
};

export const AeIcons = {
  Customer: withIcon(customer.ReactComponent, "customer-icon"),
  Edge: withIcon(edge.ReactComponent, "edge-icon"),
  Issue: withIcon(issue.ReactComponent, "issue-icon"),
  Ticket: withIcon(ticket.ReactComponent, "ticket-icon"),
  EdgesIcon: withIcon(edgesIcon.ReactComponent, "edges-icon"),
  EditIcon: withIcon(editIcon.ReactComponent, "edit-icon"),
  TickeIcon: withIcon(tickeIcon.ReactComponent, "ticke-icon"),
  ArticleIcon: withIcon(articleIcon.ReactComponent, "article-icon"),
  PdfIcon: withIcon(pdfIcon.ReactComponent, "pdf-icon"),
  IssuesIcon: withIcon(issuesIcon.ReactComponent, "issues-icon"),
  SocialIcon: withIcon(socialIcon.ReactComponent, "social-icon"),
  Sharepoint: withIcon(sharepointIcon.ReactComponent, "sharepoint-icon"),
  Slack: withIcon(slackIcon.ReactComponent, "slack-icon"),
  Link: withIcon(link.ReactComponent, "link-icon"),
  Lock: withIcon(lock.ReactComponent, "lock-icon"),
  Checked: withIcon(checked.ReactComponent, "checked-icon"),
  DeleteIcon: withIcon(deleteIcon.ReactComponent, "delete-action-icon"),
  EdgeSR: withIcon(edgeSR.ReactComponent, "edgeSR-icon"),
  Issues: withIcon(issues.ReactComponent, "issues-icon"),
  Tickets: withIcon(tickets.ReactComponent, "tickets-icon"),
  Watch: withIcon(watch.ReactComponent, "watch-icon"),
  ChevronDown: withIcon(chevronDown.ReactComponent, "chevron-icon"),
  Confluence: withIcon(confluence.ReactComponent, "confluence-icon"),
  Salesforce: withIcon(salesforce.ReactComponent, "salesforce-icon"),
  Zendesk: withIcon(zendesk.ReactComponent, "zendesk-icon"),
  AzureDevops: withIcon(azureDevops.ReactComponent, "azureDevops-icon"),
  Notion: withIcon(notion.ReactComponent, "notion-icon"),
  OpenInWeb: withIcon(openInWeb.ReactComponent, "openInWeb-icon"),
  LogoutLeftIG: withIcon(logoutLeftIG.ReactComponent, "logoutLeftIG-icon"),
  DocIcon: withIcon(docIcon.ReactComponent, "doc-icon"),
  User: withIcon(user.ReactComponent, "user-icon"),
  Error: withIcon(error.ReactComponent, "error-icon"),
  NoRecentSearches: withIcon(
    noRecentSearches.ReactComponent,
    "no-recent-searches-icon"
  ),
  NoResultFound: withIcon(noResultFound.ReactComponent, "no-result-found-icon"),
  ReStart: withIcon(reStart.ReactComponent, "re-start-icon"),
  MSIcon: withIcon(msIcon.ReactComponent, "ms-icon"),
  GoogleIcon: withIcon(googleIcon.ReactComponent, "google"),
  MSTeamsIcon: withIcon(msTeamsIcon.ReactComponent, "ms-teams-icon"),
  UserOutline: withIcon(userOutline.ReactComponent, "user-line-icon"),
  Building: withIcon(building.ReactComponent, "building-icon"),
  SortCheck: withIcon(sortCheck.ReactComponent, "sort-check-icon"),
  SearchSort: withIcon(searchSort.ReactComponent, "search-sort-icon"),
  AutoLink: withIcon(autoLink.ReactComponent, "auto-link-icon"),
  AutoReply: withIcon(autoReply.ReactComponent, "auto-reply-icon"),
  CreatedOn: withIcon(createdOn.ReactComponent, "created-on-icon"),
  UpdatedOn: withIcon(updatedOn.ReactComponent, "updated-on-icon"),
  CloseIcon: withIcon(closeIcon.ReactComponent, "close-icon"),
  Copy: withIcon(copy.ReactComponent, "copy-icon"),
  OpenLink: withIcon(openInWeb.ReactComponent, "open-link-icon"),
  ModelBot: withIcon(modelBot.ReactComponent, "model-icon"),
  ThumbsUp: withIcon(thumbsUp.ReactComponent, "thumbsUp-icon"),
  ThumbsDown: withIcon(thumbsDown.ReactComponent, "thumbsDown-icon"),
  GreenCheck: withIcon(greenCheck.ReactComponent, "green-check-icon"),
  SNow: withIcon(snow.ReactComponent, "snow-icon"),
  CheckedSquare: withIcon(checkedSquare.ReactComponent, "check-square-icon"),
  UnCheckedSquare: withIcon(
    uncheckedSquare.ReactComponent,
    "uncheck-square-icon"
  ),

  Preview: withIcon(preview.ReactComponent, "preview-icon"),
  Globe: withIcon(globe.ReactComponent, "globe-icon"),
  AiBot: withIcon(aiBot.ReactComponent, "ai-bot-icon"),
  AiSetting: withIcon(aiSetting.ReactComponent, "ai-setting-icon"),
  AiExpand: withIcon(aiExpand.ReactComponent, "ai-expand-icon"),
  AiSendIcon: withIcon(sendIcon.ReactComponent, "ai-send-icon"),
  AiAddFilter: withIcon(aiAddFilter.ReactComponent, "ai-filter-icon"),
  Refresh: withIcon(refresh.ReactComponent, "ai-refresh-icon"),
  Doc360: withIcon(doc360.ReactComponent, "ai-doc360-icon"),
  MadCap: withIcon(madCap.ReactComponent, "ai-madCap-icon"),
  Docusaurus: withIcon(docusaurus.ReactComponent, "ai-docusaurus-icon"),
  CxOne: withIcon(cxOne.ReactComponent, "ai-cxOne-icon"),
  Inbox: withIcon(inbox.ReactComponent, "ai-inbox-icon"),
  AiBotWithoutCircle: withIcon(
    aiBotWithoutCircle.ReactComponent,
    "ai-bot-without-circle-icon"
  ),
  AiPeople: withIcon(aiPeople.ReactComponent, "ai-people-icon"),
  GeneratedKnowledgeBlue: withIcon(
    generatedKnowledgeBlue.ReactComponent,
    "generated-knowledge-blue-icon"
  ),
  GeneratedKnowledgeBlueLarge: withIcon(
    generatedKnowledgeBlueLarge.ReactComponent,
    "generated-knowledge-blue-large-icon"
  ),
  GeneratedKnowledgeGrayLarge: withIcon(
    generatedKnowledgeGrayLarge.ReactComponent,
    "generated-knowledge-gray-large-icon"
  ),
  ThumbsUpSelected: withIcon(
    thumbsUpSelected.ReactComponent,
    "thumbsUp-selected-icon"
  ),
  ThumbsDownSelected: withIcon(
    thumbsDownSelected.ReactComponent,
    "thumbsDown-selected-icon"
  ),
  PeaceHand: withIcon(peaceHandIcon.ReactComponent, "peace-hand-icon"),
  Trash: withIcon(trash.ReactComponent, "trash-icon"),
  Plus: withIcon(plus.ReactComponent, "plus-icon"),
  AiAnswer: withIcon(aiAnswer.ReactComponent, "ai-answer-icon"),
  InfoIcon: withIcon(infoIcon.ReactComponent, "info-icon"),

  AiAnswerPreview: withIcon(
    aiAnswerPreview.ReactComponent,
    "ai-answer-preview-icon"
  ),
  AiAnswerReload: withIcon(
    aiAnswerReload.ReactComponent,
    "ai-answer-reload-icon"
  ),
  SubmitFeedback: withIcon(
    submitFeedback.ReactComponent,
    "submit-feedback-icon"
  ),
  Positive: withIcon(positive.ReactComponent, "sentiment-icon"),
  Negative: withIcon(negative.ReactComponent, "sentiment-icon"),
  Neutral: withIcon(neutral.ReactComponent, "sentiment-icon"),
  AeLogoSmall: withIcon(aeLogoSmall.ReactComponent, "ae-logo-small"),
  HiddenFilters: withIcon(hiddenFilters.ReactComponent, "hidden-filters-icon"),
  ProductFilters: withIcon(
    productFilters.ReactComponent,
    "product-filters-icon"
  ),
  ProductFiltersLarge: withIcon(
    productFiltersLarge.ReactComponent,
    "product-filters-large-icon"
  ),
  ProductFiltersMenu: withIcon(
    productFiltersMenu.ReactComponent,
    "product-filters-menu-icon"
  ),
  ProductFiltersSelected: withIcon(
    productFiltersSelected.ReactComponent,
    "product-filters-selected-icon"
  ),
  ProductFiltersUnselected: withIcon(
    productFiltersUnselected.ReactComponent,
    "product-filters-unselected-icon"
  ),
  QuickFilters: withIcon(quickFilters.ReactComponent, "quick-filters-icon"),
  QuickFiltersLarge: withIcon(
    quickFiltersLarge.ReactComponent,
    "quick-filters-large-icon"
  ),
  QuickFiltersMenu: withIcon(
    quickFiltersMenu.ReactComponent,
    "quick-filters-menu-icon"
  ),
  QuickFiltersUnselected: withIcon(
    quickFiltersUnselected.ReactComponent,
    "quick-filters-unselected-icon"
  )
};
