import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { SEARCH_RESULT_TYPE } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import CreateArticleButton from "../../components/CreateArticleButton/CreateArticleButton";
import { PageContent } from "../../components/PageContent/PageContent";
import { StyledLink } from "../../components/StyledLink/StyledLink";
import { TicketContent } from "../../components/TicketContent/TicketContent";
import WithLoading from "../../components/WithLoading/WithLoading";
import {
  useTicketMutation,
  useTicketQuery
} from "../../hooks/clients/useTicket";
import { Routes } from "../../routes/routes";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import styles from "./TicketPage.module.scss";

type TParams = { ticketId: string };

const TicketPage: React.FC = () => {
  const { flags } = useFlags();
  const params = useParams<TParams>();

  const ticketId = params.ticketId;

  const ticketQuery = useTicketQuery(
    false,
    ticketId,
    SEARCH_RESULT_TYPE.TICKET
  );
  const ticketMutation = useTicketMutation();

  const { data: ticket, isLoading } = ticketQuery;

  if (!ticket && !isLoading) {
    return <NotFoundPage />;
  }

  return (
    <PageContent>
      <Container>
        {ticket && (
          <>
            <div className="d-flex justify-content-between mb-4">
              <Breadcrumbs>
                <StyledLink to={Routes.TICKETS}>Tickets</StyledLink>
                <span data-testid="external-id">{ticket.displayId}</span>
              </Breadcrumbs>
              {flags.knowledgeCreation && (
                <div className={styles.buttonContainer}>
                  <CreateArticleButton ticket={ticket} />
                </div>
              )}
            </div>
            <WithLoading
              isLoading={ticketMutation.isLoading || isLoading}
              fallback={
                <div className="d-flex justify-content-center m-auto">
                  <Spinner />
                </div>
              }
              type="overlay"
            >
              <TicketContent
                ticket={ticket}
                onTicketUpdate={(updates) =>
                  ticketMutation.mutate({
                    ticketId: ticket.id,
                    patchContent: updates
                  })
                }
              />
            </WithLoading>
          </>
        )}
      </Container>
    </PageContent>
  );
};

export { TicketPage };
