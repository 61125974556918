import { LightTooltip } from "@aptedge/lib-ui/src/components/Tooltip/LightTooltip";
import Settings from "@aptedge/lib-ui/src/features/AiAnswer/components/Footer/components/Settings/Settings";
import { Skeleton } from "@mui/material";
import { FC } from "react";
import { GTM_EVENTS } from "../../../../../src/utils/event";
import { CopyButton } from "../../../../components/CopyButton/CopyButton";
import { AeIcons } from "../../../../components/Icon/AeIcons";
import { LameSuspense } from "../../../../components/LameSuspense/LameSuspense";
import { useFlags } from "../../../../context/FlagsContext/FlagsContext";
import { ANSWER_CONTENT_ID } from "../Body/Body";
import { useActionButtons } from "./useActionButtons";
import "./ActionButtons.scss";

export const ActionButtons: FC = () => {
  // weird TS issue causes a type checking error in ref={ref} below; workaround per suggestion in
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/35572#issuecomment-713241689
  const { answer, onRefetchClick } = useActionButtons();
  const { flags } = useFlags();

  return (
    <div className="action-buttons-container">
      <LightTooltip title="Copy" placement="top" arrow>
        <div>
          <LameSuspense
            isLoading={!answer}
            fallback={<Skeleton width={30} height={30} />}
          >
            <CopyButton
              text={answer}
              copiedElementId={ANSWER_CONTENT_ID}
              event={GTM_EVENTS.GTM_ANSWER_COPIED}
            />
          </LameSuspense>
        </div>
      </LightTooltip>
      <LightTooltip title="Regenerate" placement="top" arrow>
        <div>
          <LameSuspense
            isLoading={!answer}
            fallback={<Skeleton width={30} height={30} />}
          >
            <AeIcons.Refresh onClick={onRefetchClick} />
          </LameSuspense>
        </div>
      </LightTooltip>
      {flags.answergptModelSettingsUI && (
        <LightTooltip title="Settings" placement="top" arrow>
          <div>
            <LameSuspense
              isLoading={!answer}
              fallback={<Skeleton width={30} height={30} />}
            >
              <Settings onRefetchClick={onRefetchClick} />
            </LameSuspense>
          </div>
        </LightTooltip>
      )}
    </div>
  );
};
