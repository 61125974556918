import { getBackendApiUrl } from "../../config";
import { CssConfig } from "../../pages/SettingsPage/CSSConfiguration/utils/configFieldKeys";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

function createCssConfig(
  key: string,
  payload: string | boolean | Array<Record<string, string | boolean>>
): Promise<CssConfig> {
  const url = `${getBackendApiUrl()}/config/${key}/`;
  const options = post({ value: payload });
  return request(url, options);
}

export { createCssConfig };
