import {
  ConfigResponse,
  ResourceLink
} from "@aptedge/lib-ui/src/types/selfService";
import { SyntheticEvent } from "react";

export const SELF_SERVICE_TITLE = "SELF_SERVICE_TITLE";
export const SELF_SERVICE_USAGE_LIMIT = "SELF_SERVICE_USAGE_LIMIT";
export const SELF_SERVICE_TICKET_CREATION_URL =
  "SELF_SERVICE_TICKET_CREATION_URL";
export const SELF_SERVICE_TICKET_CREATION_LINK_TEXT =
  "SELF_SERVICE_TICKET_CREATION_LINK_TEXT";
export const SELF_SERVICE_PROACTIVE_ANSWERS_ENABLED =
  "SELF_SERVICE_PROACTIVE_ANSWERS_ENABLED";
export const SELF_SERVICE_PROACTIVE_ANSWERS_USER_AUTH_REQUIRED =
  "SELF_SERVICE_PROACTIVE_ANSWERS_USER_AUTH_REQUIRED";
export const SELF_SERVICE_PROACTIVE_ANSWERS_SEARCH_INPUT_SELECTOR =
  "SELF_SERVICE_PROACTIVE_ANSWERS_SEARCH_INPUT_SELECTOR";
export const SELF_SERVICE_PROACTIVE_ANSWERS_PAGE_GLOBS =
  "SELF_SERVICE_PROACTIVE_ANSWERS_PAGE_GLOBS";
export const SELF_SERVICE_INTERNAL_DASHBOARD_LINK =
  "SELF_SERVICE_INTERNAL_DASHBOARD_LINK";
export const SELF_SERVICE_REMAINING_SEARCHES =
  "SELF_SERVICE_REMAINING_SEARCHES";
export const SELF_SERVICE_RESOURCE_LIST_ENABLE =
  "SELF_SERVICE_RESOURCE_LIST_ENABLE";
export const SELF_SERVICE_RESOURCE_LINKS = "SELF_SERVICE_RESOURCE_LINKS";

export interface CssConfig {
  [SELF_SERVICE_PROACTIVE_ANSWERS_ENABLED]: boolean;
  [SELF_SERVICE_PROACTIVE_ANSWERS_USER_AUTH_REQUIRED]: boolean;
  [SELF_SERVICE_TITLE]: string;
  [SELF_SERVICE_USAGE_LIMIT]: number;
  [SELF_SERVICE_TICKET_CREATION_URL]: string;
  [SELF_SERVICE_TICKET_CREATION_LINK_TEXT]: string;
  [SELF_SERVICE_PROACTIVE_ANSWERS_SEARCH_INPUT_SELECTOR]?: string;
  [SELF_SERVICE_PROACTIVE_ANSWERS_PAGE_GLOBS]: string[];
  [SELF_SERVICE_INTERNAL_DASHBOARD_LINK]?: string;
  [SELF_SERVICE_REMAINING_SEARCHES]: number;
  [SELF_SERVICE_RESOURCE_LIST_ENABLE]: boolean;
  [SELF_SERVICE_RESOURCE_LINKS]: ResourceLink[];
}

export type ConfigProps = {
  config: CssConfig | null;
  originalConfig?: CssConfig | null;
  handleConfigChange: (event: SyntheticEvent<HTMLInputElement>) => void;
  handleAddNewPageGlob?: (key: string, value: string) => void;
  removePageGlob?: (key: string, value: string) => void;
  divider?: boolean;
  isLoading: boolean;
  setIsError?: React.Dispatch<React.SetStateAction<string[]>>;
};

export const processConfig = (config: ConfigResponse): CssConfig => {
  const updatedConfig = {
    SELF_SERVICE_PROACTIVE_ANSWERS_ENABLED:
      config.proactive_answers?.enabled || false,
    SELF_SERVICE_TITLE: config.title || "",
    SELF_SERVICE_USAGE_LIMIT: config.usage_limit || 0,
    SELF_SERVICE_TICKET_CREATION_URL: config.ticket_creation?.url || "",
    SELF_SERVICE_TICKET_CREATION_LINK_TEXT:
      config.ticket_creation?.link_text || "",
    SELF_SERVICE_PROACTIVE_ANSWERS_SEARCH_INPUT_SELECTOR:
      config.proactive_answers?.search_input_selector || "",
    SELF_SERVICE_PROACTIVE_ANSWERS_PAGE_GLOBS:
      config.proactive_answers?.page_globs || [],
    SELF_SERVICE_INTERNAL_DASHBOARD_LINK:
      config?.internal_config?.dashboard_link || "",
    SELF_SERVICE_PROACTIVE_ANSWERS_USER_AUTH_REQUIRED:
      config?.proactive_answers?.user_auth_required || false,
    SELF_SERVICE_REMAINING_SEARCHES: config?.searches_remaining || 0,
    SELF_SERVICE_RESOURCE_LIST_ENABLE:
      config.proactive_answers.enabled || false,
    SELF_SERVICE_RESOURCE_LINKS: config.resource_links || []
  };

  return updatedConfig;
};
