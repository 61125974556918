import useAiAnswer from "@aptedge/lib-ui/src/features/AiAnswer/components/AiAnswer/useAiAnswer";
import Body from "@aptedge/lib-ui/src/features/AiAnswer/components/Body/Body";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import { FC, useEffect, useRef } from "react";
import Header from "./Header/Header";
import "./AiAnswerPreview.scss";

const AiAnswerPreview: FC = () => {
  const { mappedResults, totalSearchResults } = useAiAnswer();
  const { answer, isAnswerLoading, additionalInstruction } = useAppSelector(
    (state) => state.answerGPT
  );

  const containerRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current?.scrollHeight;
    }
  }, [answer]);

  return (
    <div className="answer-preview-container" ref={containerRef}>
      <Header />
      <Body
        answer={answer ?? ""}
        skeletonLines={3}
        isLoading={isAnswerLoading}
        resultsMap={mappedResults}
        totalResults={totalSearchResults}
        hasAdditionalInstruction={!!additionalInstruction}
        truncateLongAnswer={false}
      />
    </div>
  );
};

export default AiAnswerPreview;
