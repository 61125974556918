import { IEdgeResult } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { toQueryString } from "../utils/url";

async function edgeSearch(args: { q?: string }): Promise<IEdgeResult[]> {
  const encodedQuery = toQueryString(args);
  const url = `${getBackendApiUrl()}/search2/new/?${encodedQuery}&group_type=edges`;

  return request(url);
}

export { edgeSearch };
