import { ActionBar } from "@aptedge/lib-ui/src/components/ActionBar/ActionBar";
import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Dropdown } from "@aptedge/lib-ui/src/components/Dropdown/Dropdown";
import { Icon } from "@aptedge/lib-ui/src/components/Icon/Icon";
import { List } from "@aptedge/lib-ui/src/components/List/List";
import { ModalFooter } from "@aptedge/lib-ui/src/components/Modal/Modal";
import {
  NoData,
  NoDataMessage
} from "@aptedge/lib-ui/src/components/NoData/NoData";
import { Spacer } from "@aptedge/lib-ui/src/components/Spacer/Spacer";
import { Search } from "@aptedge/lib-ui/src/features/Search/Search";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import { IUserListData, UserRole } from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import React from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQuery } from "react-query";
import { ToastType } from "react-toastify";
import { deleteUser } from "../../../clients/AdminDashboard/deleteUser";
import { fetchUsersList } from "../../../clients/AdminDashboard/fetchUsersList";
import { updateUser } from "../../../clients/AdminDashboard/updateUser";
import { WebCacheKey } from "../../../clients/cache";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { PaginationBar } from "../../../components/PaginationBar/PaginationBar";
import { Toast } from "../../../components/Toast/Toast";
import { ClientStatus } from "../../../types/clients";
import { UserListItem } from "./UserListItem";
import "./AdminDashboard.scss";

const ADMIN_LIST_HEADER = {
  avatar: "Avatar",
  email: "Email",
  role: "Role",
  delete: "..."
};

const PER_PAGE_OPTIONS = [20, 50, 100];

const AdminDashboard = (): React.ReactElement => {
  const [perPage, setPerPage] = React.useState(PER_PAGE_OPTIONS[0]);
  const [page, setPage] = React.useState<number>(1);

  const { searchQuery } = useAppSelector((state) => state.search);
  const [clientSStatus, setClientStatus] = React.useState<ClientStatus>(
    ClientStatus.PROMPT
  );
  const [toastMsg, setToastMsg] = React.useState<string>("");
  const [
    selectedDeleteUser,
    setSelectedDeleteUser
  ] = React.useState<IUserListData | null>(null);
  const [
    selectedUpdateUser,
    setSelectedUpdateUser
  ] = React.useState<IUserListData | null>(null);
  const [selectedUserRole, setSelectedUserRole] = React.useState<UserRole>();

  const fetchUsersListQuery = useQuery(
    [WebCacheKey.USER_LIST, searchQuery, page, perPage],
    () =>
      fetchUsersList({
        search: searchQuery,
        page: page,
        paginate: "True",
        "per-page": perPage
      }),
    { enabled: true }
  );
  const isLoading = fetchUsersListQuery.isLoading;
  const usersList = fetchUsersListQuery?.data ?? null;
  const deleteUserData = useMutation(deleteUser, {
    onError: () => {
      setToastMsg("Unable to delete user. Something went wrong");
      setClientStatus(ClientStatus.ERROR);
      setSelectedDeleteUser(null);
    },
    onSuccess: () => {
      setToastMsg("User deactivated successfully");
      setClientStatus(ClientStatus.SUCCESS);
      setSelectedDeleteUser(null);
      fetchUsersListQuery.refetch();
    }
  });
  const updateUserData = useMutation(updateUser, {
    onError: () => {
      setToastMsg("Unable to update user details. Something went wrong");
      setClientStatus(ClientStatus.ERROR);
      setSelectedUpdateUser(null);
    },
    onSuccess: () => {
      setToastMsg("User updated successfully");
      setClientStatus(ClientStatus.SUCCESS);
      setSelectedUpdateUser(null);
      fetchUsersListQuery.refetch();
    }
  });

  const handleDeleteUser = (val: IUserListData): void => {
    setToastMsg("");
    setSelectedDeleteUser(val);
    setSelectedUpdateUser(null);
  };

  const handleUpdateUser = (val: IUserListData, role: UserRole): void => {
    setToastMsg("");
    setSelectedDeleteUser(null);
    setSelectedUpdateUser(val);
    setSelectedUserRole(role);
  };

  const handleUserState = (isUpdate: boolean): void => {
    setClientStatus(ClientStatus.LOADING);
    if (isUpdate) {
      selectedUpdateUser?.id &&
        updateUserData.mutate({
          userId: selectedUpdateUser?.id,
          patchContent: {
            admin: selectedUserRole === UserRole.ADMIN ? true : false,
            role: selectedUserRole
          }
        });
    } else {
      selectedDeleteUser?.id &&
        deleteUserData.mutate({ userId: selectedDeleteUser?.id });
    }
    dataLayerPush({ event: GTM_EVENTS.GTM_ARCHIVE_EDGE });
  };

  return (
    <div>
      <Helmet>
        <title>Admin Dashboard</title>
      </Helmet>
      <div className="user-list-header-section">
        <Search className="user-search" placeholder="Search for user" />
        <div
          className="per-page-container"
          onClick={(e) => e.stopPropagation()}
          role="button"
        >
          <Dropdown.Container className="options">
            <Dropdown.Toggle>
              <span className="per-page-header">
                Results per page: {perPage} <Icon icon={faCaretDown} />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {PER_PAGE_OPTIONS.map((val: number) => (
                <Dropdown.Item key={val} onClick={() => setPerPage(val)}>
                  <span>{val}</span>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown.Container>
        </div>
      </div>

      <div className="edges-content">
        <div className="container-xxl">
          <List>
            <div className="user-list user-list-header">
              <div className="user-header-txt">{ADMIN_LIST_HEADER.avatar}</div>
              <div className="user-header-txt">{ADMIN_LIST_HEADER.email}</div>
              <div className="user-header-txt">{ADMIN_LIST_HEADER.role}</div>
              <div className="user-header-txt">{ADMIN_LIST_HEADER.delete}</div>
            </div>
            {usersList?.items?.length
              ? usersList?.items?.map(
                  (user: IUserListData, index: number): JSX.Element | null => {
                    if (!user.banned) {
                      return (
                        <UserListItem
                          key={user.id}
                          user={user}
                          index={index}
                          handleDelete={() => handleDeleteUser(user)}
                          onOptionSelect={(role) =>
                            handleUpdateUser(user, role)
                          }
                        />
                      );
                    }
                    return null;
                  }
                )
              : !isLoading && <NoData message={NoDataMessage.NO_USERS_FOUND} />}
          </List>
          {usersList?.items && (
            <PaginationBar
              className="mt-3"
              page={usersList?.page}
              totalPages={usersList?.totalPages}
              onChange={(val) => setPage(val?.page ?? 1)}
            />
          )}
        </div>
      </div>
      {fetchUsersListQuery.error && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}
      {toastMsg && <Toast type={ToastType.DEFAULT}>{toastMsg}</Toast>}
      <ConfirmationModal
        title={`Deactivate user`}
        status={clientSStatus}
        isOpen={Boolean(selectedDeleteUser)}
        loadingTitle="Deleting User..."
        onClose={() => setSelectedDeleteUser(null)}
      >
        <p>Are you sure you want to deactivate the following user?</p>
        <p>{selectedDeleteUser?.email}</p>
        <ModalFooter>
          <ActionBar.Container>
            <ActionBar.Right>
              <Spacer flex row size="medium">
                <Button
                  color="secondary"
                  onClick={() => setSelectedDeleteUser(null)}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="delete button"
                  color="danger"
                  onClick={() => handleUserState(false)}
                >
                  Deactivate
                </Button>
              </Spacer>
            </ActionBar.Right>
          </ActionBar.Container>
        </ModalFooter>
      </ConfirmationModal>
      <ConfirmationModal
        title={`Update user role`}
        status={clientSStatus}
        isOpen={Boolean(selectedUpdateUser)}
        loadingTitle="Updating User..."
        onClose={() => setSelectedUpdateUser(null)}
      >
        <p>Are you sure you want to update user role?</p>
        <span>
          {`${selectedUpdateUser?.email} from ${selectedUpdateUser?.role} to ${selectedUserRole}`}
        </span>
        <ModalFooter>
          <ActionBar.Container>
            <ActionBar.Right>
              <Spacer flex row size="medium">
                <Button
                  color="secondary"
                  onClick={() => setSelectedUpdateUser(null)}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="delete button"
                  onClick={() => handleUserState(true)}
                >
                  Update
                </Button>
              </Spacer>
            </ActionBar.Right>
          </ActionBar.Container>
        </ModalFooter>
      </ConfirmationModal>
    </div>
  );
};
export { AdminDashboard };
