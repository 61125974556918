import AEIcon from "@aptedge/lib-ui/src/components/GMIcon/Icon";
import IOSSwitch from "@aptedge/lib-ui/src/components/IOSSwitch/IOSSwitch";
import { ResourceLink } from "@aptedge/lib-ui/src/types/selfService";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { FC, SyntheticEvent, useState } from "react";
import { createCssConfig } from "../../../../../clients/CssConfig";
import SectionLayout from "../../../common/SectionLayout/SectionLayout";
import ConfigInput from "../../ConfigInput/ConfigInput";
import {
  CssConfig,
  SELF_SERVICE_RESOURCE_LINKS,
  SELF_SERVICE_RESOURCE_LIST_ENABLE
} from "../../utils/configFieldKeys";
import { AddedResources } from "./AddedResources/AddedResources";
import { ResourceInput } from "./ResourceInput/ResourceInput";
import styles from "./styles.module.scss";

type Props = {
  config: CssConfig;
  isLoading: boolean;
  refetchConfig: () => void;
  handleConfigChange: (event: SyntheticEvent<HTMLInputElement>) => void;
};
const ResourceSettings: FC<Props> = ({
  config,
  isLoading,
  refetchConfig,
  handleConfigChange
}) => {
  const resourceList = config?.[SELF_SERVICE_RESOURCE_LINKS];

  const [addMode, setAddMode] = useState(false);

  const onAddNewResourceItem = async (
    resourceItem: ResourceLink
  ): Promise<void> => {
    if (isEmpty(resourceItem)) return;
    try {
      await createCssConfig(
        SELF_SERVICE_RESOURCE_LINKS,
        JSON.stringify(resourceItem)
      );
      refetchConfig();
      setAddMode(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <SectionLayout title="Resource">
        <ConfigInput
          description="Show resources in self service app"
          className={classNames(styles.configInput, styles.borderedInput)}
          titleClassName={styles.switchInputTitle}
        >
          <IOSSwitch
            checked={config?.[SELF_SERVICE_RESOURCE_LIST_ENABLE] || false}
            name={SELF_SERVICE_RESOURCE_LIST_ENABLE}
            onChange={handleConfigChange}
            disabled={isLoading}
          />
        </ConfigInput>
        <ConfigInput title="Resource URLs" className={styles.topSpacing}>
          <ConfigInput
            description="Setup your first resource"
            className={styles.inputContainer}
          >
            {resourceList?.length > 0 ? (
              <AddedResources
                resourceList={resourceList}
                onAddNewResourceItem={onAddNewResourceItem}
                refetchConfig={refetchConfig}
              />
            ) : (
              <ResourceInput onAddNewResourceItem={onAddNewResourceItem} />
            )}
          </ConfigInput>
          {resourceList?.length > 0 && (
            <>
              {addMode ? (
                <ConfigInput className={styles.inputContainer}>
                  <ResourceInput onAddNewResourceItem={onAddNewResourceItem} />
                </ConfigInput>
              ) : (
                <button
                  onClick={() => setAddMode(true)}
                  className={styles.addButton}
                >
                  <AEIcon name="add" />
                  <span>Add</span>
                </button>
              )}
            </>
          )}
        </ConfigInput>
      </SectionLayout>
    </div>
  );
};

export default ResourceSettings;
