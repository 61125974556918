import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import * as React from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { ToastType } from "react-toastify";
import { WebCacheKey } from "../../../clients/cache";
import { saveMyProducts } from "../../../clients/MyProducts/saveMyProducts";
import { updateMyProducts } from "../../../clients/MyProducts/updateMyProducts";
import { AptAnswersErrorMessage } from "../../../components/AptAnswers/AptAnswersErrorMessage";
import { Toast } from "../../../components/Toast/Toast";
import { useAuth } from "../../../context/AuthContext";
import { useMyProducts } from "../../../hooks/clients/useMyProducts";
import { Routes } from "../../../routes/routes";
import { PageHeader } from "../Filters/PageHeader";
import styles from "./MyProducts.module.scss";
import { MyProductsActions } from "./MyProductsActions";
import { MyProductsItems } from "./MyProductsItems";

const MyProducts: React.FC = () => {
  const { user } = useAuth();
  const {
    isEmpty,
    isError,
    isLoading,
    isSuccess,
    myProductsFilterId,
    productItems,
    savedSelectedIds
  } = useMyProducts();

  const [selectedIds, setSelectedIds] = React.useState(savedSelectedIds);
  const [toastMessage, setToastMessage] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (savedSelectedIds.length) {
      setSelectedIds(savedSelectedIds);
    }
  }, [savedSelectedIds]);

  // Reset toast state back to null after the toast is rendered
  React.useEffect(() => {
    setTimeout(() => {
      setToastMessage(null);
    }, 500);
  }, [toastMessage]);

  const queryClient = useQueryClient();

  const saveMyProductsMutation = useMutation(saveMyProducts, {
    onSuccess: () => {
      queryClient.invalidateQueries(WebCacheKey.APT_ANSWERS_MY_PRODUCTS);
      setToastMessage("SUCCESS");
    },
    onError: () => {
      setToastMessage("ERROR");
    }
  });

  const updateMyProductsMutation = useMutation(updateMyProducts, {
    onSuccess: () => {
      queryClient.invalidateQueries(WebCacheKey.APT_ANSWERS_MY_PRODUCTS);
      setToastMessage("SUCCESS");
    },
    onError: () => {
      setToastMessage("ERROR");
    }
  });

  const emptyStateMessage =
    "There are no saved product filters. To use My Products, please create a product filter.";
  const errorMessage =
    "There was an error loading My Products. Please refresh the page or contact AptEdge for further support.";

  const handleItemClick = (itemId: string): void => {
    const items = selectedIds.includes(itemId)
      ? selectedIds.filter((i) => i !== itemId)
      : [...selectedIds, itemId];
    setSelectedIds(items);
  };

  const handleCancel = (): void => {
    setSelectedIds(savedSelectedIds);
  };

  const handleSave = (): void => {
    myProductsFilterId
      ? updateMyProductsMutation.mutate({ myProductsFilterId, selectedIds })
      : saveMyProductsMutation.mutate({ selectedIds });
  };

  return (
    <div className={styles.container}>
      <PageHeader
        title="My products"
        subtitle="Configure which products to include in your search results by default"
      />
      {isLoading && <Spinner />}
      {isError && <AptAnswersErrorMessage message={errorMessage} />}
      {isSuccess && isEmpty && (
        <div className={styles.emptyStateContainer}>
          <p className={styles.emptyStateDescription}>{emptyStateMessage}</p>
          {user?.admin ? (
            <Link to={Routes.SETTINGS_FILTERS}>
              <button className={styles.createFilterButton}>
                Create a product filter
              </button>
            </Link>
          ) : null}
        </div>
      )}
      {isSuccess && !isEmpty && (
        <>
          <MyProductsItems
            items={productItems}
            handleItemClick={handleItemClick}
            selectedIds={selectedIds}
          />
          <MyProductsActions
            handleCancel={handleCancel}
            handleSave={handleSave}
          />
        </>
      )}
      {toastMessage === "SUCCESS" ? (
        <Toast type={ToastType.SUCCESS}>My Products saved successfully</Toast>
      ) : null}
      {toastMessage === "ERROR" ? (
        <Toast type={ToastType.ERROR}>
          There was an error saving My Products. Please refresh the page and try
          again.
        </Toast>
      ) : null}
    </div>
  );
};

export { MyProducts };
