import React from "react";
import { useQuery, UseQueryResult } from "react-query";
import { createSelfServiceToken } from "../../../clients/Account/createSelfServiceToken";
import { getBackendApiUrl } from "../../../config";
import { request } from "../../../utils/request";
import { Token } from "./Token";

const SELF_SERVICE_TOKEN_CACHE_KEY = "SELF_SERVICE_TOKEN";

export function fetchSelfServiceToken(): Promise<string> {
  const url = `${getBackendApiUrl()}/config/SELF_SERVICE_TOKEN/`;
  return request<string>(url);
}

export const useFetchSelfServiceToken = (): UseQueryResult<string> => {
  return useQuery(SELF_SERVICE_TOKEN_CACHE_KEY, fetchSelfServiceToken);
};

function SelfServiceConfiguration(): React.ReactElement {
  const selfServiceTokenQuery = useFetchSelfServiceToken();

  return (
    <>
      <div>
        <h5>Setup</h5>
        <p>Be sure to use the Token below when setting up our app.</p>
        <div className="settings">
          <div className="mt-3">
            <label htmlFor="token">Token</label>
            <Token
              value={selfServiceTokenQuery.data}
              createToken={createSelfServiceToken}
              onTokenCreated={selfServiceTokenQuery.refetch}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export { SelfServiceConfiguration };
