import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export type Warning = {
  title: string;
  description: string;
};
export enum AnswerStage {
  start = "start",
  results = "results",
  identify = "identify",
  finished = "finished",
  question = "question"
}

type InitialState = {
  isF1AppLoaded: boolean;
  answerStage: AnswerStage;
  startPage: string | null;
  finishedMessage: string | null;
  warning: Warning | null;
  showMeHowClicked: boolean;
  isError: boolean;
  modalVisibility: boolean;
  badStepCount: number;
  activeStepIndex: number;
  isIndentifyQueryLoading: boolean;
  identifyAnswerId: string;
  warningAnswerId: string;
  nextStepAnswerId: string;
  correctStepAnswerId: string;
  isPreviewMode?: boolean;
  sessionData?: string;
};

const initialState: InitialState = {
  isF1AppLoaded: false,
  isPreviewMode: false,
  answerStage: AnswerStage.start,
  startPage: null,
  finishedMessage: null,
  modalVisibility: false,
  warning: null,
  isIndentifyQueryLoading: false,
  showMeHowClicked: false,
  isError: false,
  badStepCount: 0,
  activeStepIndex: 0,
  identifyAnswerId: uuidv4(),
  warningAnswerId: uuidv4(),
  nextStepAnswerId: uuidv4(),
  correctStepAnswerId: uuidv4(),
  sessionData: ""
};

const embeddedAppSlice = createSlice({
  name: "embedded",
  initialState,
  reducers: {
    setAnswerStage: (state, action) => {
      state.answerStage = action.payload;
    },
    setStartPage: (state, action: PayloadAction<string | null>) => {
      state.startPage = action.payload;
    },
    setFinishedMessage: (state, action) => {
      state.finishedMessage = action.payload;
    },
    setWarning: (state, action) => {
      state.warning = action.payload;
    },
    setShowMeHowClicked: (state, action) => {
      state.showMeHowClicked = action.payload;
    },
    setIsError: (state, action) => {
      state.isError = action.payload;
    },
    setBadStepCount: (state, action) => {
      state.badStepCount = action.payload;
    },
    setActiveStepIndex: (state, action) => {
      state.activeStepIndex = action.payload;
    },
    setIdentifyAnswerId: (state, action) => {
      state.identifyAnswerId = action.payload;
    },
    setWarningAnswerId: (state, action) => {
      state.warningAnswerId = action.payload;
    },
    setNextStepAnswerId: (state, action) => {
      state.nextStepAnswerId = action.payload;
    },
    setCorrectStepAnswerId: (state, action) => {
      state.correctStepAnswerId = action.payload;
    },
    resetEmbeddedApp: (state) => {
      state.answerStage = AnswerStage.identify;
      state.startPage = null;
      state.finishedMessage = null;
      state.warning = null;
      state.showMeHowClicked = false;
      state.isError = false;
      state.activeStepIndex = 0;
      state.badStepCount = 0;
      state.identifyAnswerId = uuidv4();
      state.warningAnswerId = uuidv4();
      state.nextStepAnswerId = uuidv4();
      state.badStepCount = 0;
      state.correctStepAnswerId = uuidv4();
    },
    setIsF1AppLoaded: (state, action) => {
      state.isF1AppLoaded = action.payload;
    },
    setPreviewMode: (state, action) => {
      state.isPreviewMode = action.payload;
    },
    setModalVisibility: (state, action) => {
      state.modalVisibility = action.payload;
    },
    setIsIndentifyQueryLoading: (state, action) => {
      state.isIndentifyQueryLoading = action.payload;
    },
    setSessionData: (state, action) => {
      state.sessionData = action.payload;
    }
  }
});

export const {
  setAnswerStage,
  setStartPage,
  setFinishedMessage,
  resetEmbeddedApp,
  setWarning,
  setShowMeHowClicked,
  setIsError,
  setIsIndentifyQueryLoading,
  setBadStepCount,
  setActiveStepIndex,
  setIdentifyAnswerId,
  setWarningAnswerId,
  setNextStepAnswerId,
  setCorrectStepAnswerId,
  setIsF1AppLoaded,
  setModalVisibility,
  setPreviewMode,
  setSessionData
} = embeddedAppSlice.actions;

export default embeddedAppSlice.reducer;
