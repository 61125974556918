import AEIcon from "@aptedge/lib-ui/src/components/GMIcon/Icon";
import { ResourceLink } from "@aptedge/lib-ui/src/types/selfService";
import { FC } from "react";
import { deleteCssConfig } from "../../../../../../clients/CssConfig";
import { SELF_SERVICE_RESOURCE_LINKS } from "../../../utils/configFieldKeys";
// import { ResourceInput } from "./ResourceInput";
import styles from "../styles.module.scss";

export const AddedResources: FC<{
  resourceList: ResourceLink[];
  onAddNewResourceItem: (resourceItem: ResourceLink) => Promise<void>;
  refetchConfig: () => void;
}> = ({ resourceList, onAddNewResourceItem, refetchConfig }) => {
  // const [selectedResource, setSelectedResource] = useState<ResourceLink | null>(
  //   null
  // );
  const deleteResourceItem = async (
    key: string,
    value: ResourceLink
  ): Promise<void> => {
    await deleteCssConfig(key, JSON.stringify(value));
    refetchConfig();
  };

  return (
    <div className={styles.resourceList}>
      {resourceList?.map((resource: ResourceLink) => {
        // if (selectedResource?.title === resource.title)
        //   return (
        //     <ResourceInput
        //       key={resource.title}
        //       selectedResource={selectedResource}
        //       onAddNewResourceItem={onAddNewResourceItem}
        //     />
        //   );

        return (
          <div key={resource.title} className={styles.resourceItem}>
            <div className={styles.text}>
              <span className={styles.name}>{resource.title}</span>
              <span className={styles.url}>{resource.url}</span>
            </div>
            <div>
              {/* <AEIcon
                name="edit"
                className={styles.editIcon}
                onClick={() => setSelectedResource(resource)}
              /> */}
              <AEIcon
                name="delete"
                className={styles.deleteIcon}
                onClick={() => {
                  deleteResourceItem(SELF_SERVICE_RESOURCE_LINKS, resource);
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
