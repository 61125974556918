import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const FullWidthButton = styled(Button)<{ mode: "dark" | "light" }>(
  ({ theme, mode }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    padding: "0.5rem 1rem",
    borderRadius: "0.25rem",
    fontSize: ".8rem",
    boxShadow: "none",
    outline: "none",
    textTransform: "none",
    "&:focus": {
      outline: "none"
    },
    "&:focus-visible": {
      outline: "none"
    },

    ...(mode === "dark" && {
      backgroundColor: "var(--Viking-Viking-80, #1d5258)",
      "&:hover": {
        backgroundColor: "var(--Viking-Viking-90, #123236)",
        boxShadow: "none"
      },
      "&:active": {
        backgroundColor: "var(--Viking-Viking-100, #0b2022)",
        boxShadow: "none"
      }
    }),
    ...(mode === "light" && {
      backgroundColor: "var(--Slate-Slate-00, #FFF)",
      color: "#48566B",
      border: "1px solid var(--Slate-Slate-80, #48566B)",
      "&:hover": {
        background: "var(--Slate-Slate-20, #EAECF1)",
        boxShadow: "none"
      },
      "&:active": {
        backgroundColor: "var(--Slate-Slate-40, #B0BACA)",
        boxShadow: "none"
      }
    }),

    ".MuiButton-endIcon": {
      marginLeft: "auto"
    },

    "&.Mui-disabled": {
      cursor: "not-allowed",
      pointerEvents: "auto"
    }
  })
);
