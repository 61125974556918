import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  QUERY_PARAMS,
  SEARCH_RESULT_TYPE
} from "@aptedge/lib-ui/src/types/entities";
import { removeEmptyFilters } from "@aptedge/lib-ui/src/utils/query";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { WebCacheKey } from "../../clients/cache";
import { fetchTickets } from "../../clients/Tickets/fetchTickets";
import { PageContent } from "../../components/PageContent/PageContent";
import { PaginationSort } from "../../components/PaginationSort/PaginationSort";
import { useTicketQuery } from "../../hooks/clients/useTicket";
import { useTicketSort } from "../../hooks/useTicketSort";
import { Routes } from "../../routes/routes";
import { PaginatedTicketsListing } from "../Explorer/PaginatedTicketsListing";
import { TicketListingFilterBar } from "./TicketListingFilterBar";
import { useTicketListingQuery } from "./useTicketListingQuery";
import "./TicketListingPage.scss";

const ONE_HOUR = 60 * 1000 * 60;

const QUERY_OPTIONS = {
  // The time in milliseconds after data is considered stale. Once marked stale, RQ will refetch.
  staleTime: ONE_HOUR,
  // The time in milliseconds that unused/inactive cache (no components using it are mounted) data remains in memory.
  cacheTime: ONE_HOUR,
  refetchOnMount: false,
  refetchOnWindowFocus: false
};

function TicketListingPage(): React.ReactElement {
  const [params, setParams] = useTicketListingQuery();
  const [DEFAULT_SORT, sortOptions] = useTicketSort();
  const { queryParams } = useQueryParams();
  const { isLoading: isFlagLoading } = useFlags();
  const history = useHistory();
  const externalId = queryParams.get(QUERY_PARAMS.EXTERNAL_ID) ?? "";

  const apiParams = removeEmptyFilters(params);

  const ticketsQuery = useQuery(
    [WebCacheKey.TICKETS, apiParams],
    () => fetchTickets(apiParams),
    {
      ...QUERY_OPTIONS,
      enabled: !externalId
    }
  );

  const ticketQuery = useTicketQuery(
    true,
    externalId,
    SEARCH_RESULT_TYPE.TICKET,
    isFlagLoading
  );

  const { data: ticket, isLoading: isTicketLoading } = ticketQuery;

  useEffect(() => {
    if (ticket && !isTicketLoading) {
      history.push(`${Routes.TICKETS}/${ticket.id}`);
    }
  }, [ticket, isTicketLoading, history]);

  if (isTicketLoading) {
    return (
      <div className="d-flex justify-content-center m-auto">
        <Spinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Tickets</title>
      </Helmet>
      <PageContent className="ticket-listing-page">
        <div className="container-xxl d-flex align-items-center justify-content-between flex-nowrap mt-4 mb-2">
          <section className="w-100">
            <TicketListingFilterBar params={params} onChange={setParams} />
          </section>
          <section className="sort">
            <PaginationSort
              selected={params.sortBy}
              defaultValue={DEFAULT_SORT}
              options={sortOptions}
              onChange={setParams}
            />
          </section>
        </div>
        <div className="container-xxl">
          <PaginatedTicketsListing
            ticketsData={ticketsQuery.data}
            isLoading={ticketsQuery.isLoading}
            onPageParamsUpdate={setParams}
            onTicketUpdate={() => ticketsQuery.refetch()}
          />
        </div>
      </PageContent>
    </React.Fragment>
  );
}

export { TicketListingPage };
