import { GeneratedKnowledgeListData } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

interface FetchArticleByTicketIdArgs {
  ticketId?: number;
}

function fetchArticleByTicketId({
  ticketId
}: FetchArticleByTicketIdArgs): Promise<GeneratedKnowledgeListData> {
  const url = `${getBackendApiUrl()}/generated_knowledge/?ticket_id=${ticketId}`;
  return request(url);
}

export { fetchArticleByTicketId };
