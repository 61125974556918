import { Icon } from "@aptedge/lib-ui/src/components/Icon/Icon";
import { Modal, ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { useClipboard } from "@aptedge/lib-ui/src/hooks/useClipboard";
import { Delay } from "@aptedge/lib-ui/src/styles/animations";
import {
  SEARCH_RESULT_TYPE,
  ITicketInfo
} from "@aptedge/lib-ui/src/types/entities";
import { faClipboard } from "@fortawesome/free-solid-svg-icons/faClipboard";
import { faExpand } from "@fortawesome/free-solid-svg-icons/faExpand";
import { useHistory } from "react-router-dom";
import { ToastType } from "react-toastify";
import {
  useTicketMutation,
  useTicketQuery
} from "../../hooks/clients/useTicket";
import { useUserBatchQuery } from "../../hooks/clients/useUserBatchQuery";
import { TooltipMessage } from "../../registry/TooltipMessage";
import { Routes } from "../../routes/routes";
import { toTicketPath } from "../../routes/toPath";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import { StyledLink } from "../StyledLink/StyledLink";
import { TicketContent } from "../TicketContent/TicketContent";
import { Toast } from "../Toast/Toast";
import WithLoading from "../WithLoading/WithLoading";

interface Props {
  ticketId?: number;
  onClose: () => void;
  onTicketUpdate?: (ticket: ITicketInfo) => void;
  isOpen: boolean;
  isDisabled?: boolean;
}

function TicketModal(props: Props): React.ReactElement {
  const { ticketId, onTicketUpdate, onClose, isOpen, isDisabled } = props;

  const history = useHistory();
  const { handleCopy, copied, error } = useClipboard();

  const ticketQuery = useTicketQuery(
    false,
    ticketId,
    SEARCH_RESULT_TYPE.TICKET
  );
  const ticketMutation = useTicketMutation({ onSuccess: onTicketUpdate });

  const ticket = ticketQuery.data;
  const isLoading = ticketQuery.isLoading || ticketMutation.isLoading;
  const isError = ticketQuery.isError || ticketMutation.isError;
  const getUserIds = (): number[] | undefined => {
    const ids = [];
    ticket?.assigneeUser?.id && ids.push(ticket.assigneeUser?.id);
    ticket?.requesterUser?.id && ids.push(ticket.requesterUser?.id);
    return ids.length ? ids : undefined;
  };
  const userListData = useUserBatchQuery(getUserIds());

  const handleViewTicketPage = (): void => {
    history.push(toTicketPath(Number(ticket?.id)));
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={
          ticket && (
            <Breadcrumbs>
              <StyledLink to={Routes.TICKETS}>Tickets</StyledLink>
              <span>{ticket.displayId}</span>
            </Breadcrumbs>
          )
        }
        actions={
          <>
            {ticket && (
              <Tooltip
                delay={Delay.LONG}
                placement="top"
                content={TooltipMessage.EXPAND_PAGE}
              >
                <Icon
                  className="fa-icon"
                  icon={faExpand}
                  onClick={handleViewTicketPage}
                />
              </Tooltip>
            )}
            <Tooltip
              delay={Delay.LONG}
              placement="top"
              content={TooltipMessage.COPY_APTEDGE_LINK}
            >
              <Icon
                className="fa-icon"
                data-testid="share-icon"
                icon={faClipboard}
                onClick={() =>
                  handleCopy(`${window.location.href}/${ticketId}`)
                }
              />
            </Tooltip>
          </>
        }
        width={ModalWidth.XL}
      >
        <WithLoading
          isLoading={isLoading}
          fallback={<Spinner />}
          type="overlay"
        >
          {ticket ? (
            <TicketContent
              ticket={ticket}
              userData={userListData?.data ?? []}
              className="compact"
              onTicketUpdate={(update) =>
                ticketMutation.mutate({
                  ticketId: ticket.id,
                  patchContent: update
                })
              }
              isDisabled={isDisabled}
            />
          ) : null}
        </WithLoading>
      </Modal>
      {copied && (
        <Toast type={ToastType.INFO} autoClose={2000}>
          Copied to clipboard!
        </Toast>
      )}
      {error && (
        <Toast type={ToastType.ERROR} autoClose={2000}>
          Unable to copy to clipboard.
        </Toast>
      )}
      {isError && <Toast type="error">Something went wrong.</Toast>}
    </>
  );
}

export { TicketModal };
