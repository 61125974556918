import { ITicketInfo } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";
import { toQueryString } from "../utils/url";

function fetchTicketById(
  id: number | string,
  isKCWebApp: boolean
): Promise<ITicketInfo> {
  const query = toQueryString({
    ticketId: id
  });
  let url = `${getBackendApiUrl()}/tickets/`;
  if (isKCWebApp) {
    url += `?${query}`;
  } else {
    url += `${id}/`;
  }

  const options = isKCWebApp ? post({}) : undefined;

  return request(url, options);
}

export { fetchTicketById };
