import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { QUERY_PARAMS } from "../../types/entities";
import { isSupportApp } from "../../utils/supportApp";

export const AiModels = [
  "gpt-4o",
  "gpt-4o-2024-05-13",
  "gpt-4o-mini",
  "gpt-4o-mini-2024-07-18",
  "gpt-4-turbo",
  "gpt-4-turbo-2024-04-09",
  "gpt-4-turbo-preview",
  "gpt-4-0125-preview",
  "gpt-4-1106-preview",
  "gpt-3.5-turbo-0125",
  "gpt-3.5-turbo",
  "gpt-3.5-turbo-1106",
  "claude-3-haiku-20240307",
  "claude-3-sonnet-20240229",
  "claude-3-opus-20240229",
  "claude-3-5-sonnet-20240620",
  "meta-llama/Llama-2-70b-chat-hf",
  "default"
] as const;

export type AiModel = typeof AiModels[number];

export type Question = {
  question: string;
  description: string;
  element: string | null;
  errorDetectedInHtml: boolean;
};

type SliceStateType = {
  aiModel: AiModel;
  answer: string | null;
  answerSteps: Record<string, unknown>[];
  answerIdentification: { data: Question[]; isLoading: boolean };
  selectedQuestion: Question | null;
  isAnswerLoading: boolean;
  isAiSearch: boolean;
  answerLoadingStartTimeMillis: number | null;
  additionalInstruction: string;
  additionalInstructionFilter: string | null;
  showMoreButtonClicked: boolean;
  answerAttempt: number;
  answerCardVisibility: boolean;
  isAnswerLoaded: boolean;
  suggestedFollowupQuestions: string[];
};

const SEARCH_PATH = isSupportApp
  ? `?${window.location.hash.split("?")[1]}`
  : window.location.search;

const queryParams = new URLSearchParams(SEARCH_PATH);
const INITIAL_ADDITIONAL_INSTRUCTION =
  queryParams.get(QUERY_PARAMS.ADDITIONAL_INSTRUCTIONS) ?? "";

const initialState: SliceStateType = {
  aiModel: "default",
  answer: null,
  answerSteps: [],
  answerIdentification: { data: [], isLoading: true },
  selectedQuestion: null,
  isAnswerLoading: false,
  isAiSearch: false,
  answerLoadingStartTimeMillis: null,
  additionalInstruction: "",
  additionalInstructionFilter: INITIAL_ADDITIONAL_INSTRUCTION,
  showMoreButtonClicked: false,
  answerAttempt: 0,
  answerCardVisibility: false,
  isAnswerLoaded: false,
  suggestedFollowupQuestions: []
};

const answerGPT = createSlice({
  name: "answerGPT",
  initialState,
  reducers: {
    setAiModel: (state, action: PayloadAction<AiModel>) => {
      state.aiModel = action.payload;
    },
    updateAnswer: (state, action: PayloadAction<string | null>) => {
      state.answer = action.payload;
    },
    updateAnswerSteps: (
      state,
      action: PayloadAction<Record<string, unknown>[]>
    ) => {
      state.answerSteps = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateAnswerIdentification: (state, action: PayloadAction<any | null>) => {
      state.answerIdentification = action.payload;
    },
    updateSelectedQuestion: (state, action: PayloadAction<Question | null>) => {
      state.selectedQuestion = action.payload;
    },
    updateAdditionalInstruction: (state, action: PayloadAction<string>) => {
      state.additionalInstruction = action.payload;
    },
    updateAnswerLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isAnswerLoading = action.payload;
    },
    updateAnswerLoadingStartTimeMillis: (
      state,
      action: PayloadAction<number>
    ) => {
      state.answerLoadingStartTimeMillis = action.payload;
    },
    updateShowMoreButtonClicked: (state, action: PayloadAction<boolean>) => {
      state.showMoreButtonClicked = action.payload;
    },
    updateAnswerAttempt: (state, action: PayloadAction<number>) => {
      state.answerAttempt = action.payload;
    },
    updateAnswerCardVisibility: (state, action: PayloadAction<boolean>) => {
      state.answerCardVisibility = action.payload;
    },
    updateIsAnswerLoaded: (state, action: PayloadAction<boolean>) => {
      state.isAnswerLoaded = action.payload;
    },
    updateSuggestedFollowupQuestions: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.suggestedFollowupQuestions = action.payload;
    },
    resetGPTSearchStates: (state) => {
      state.answerIdentification = { data: [], isLoading: true };
      state.selectedQuestion = null;
      state.answerSteps = [];
    }
  }
});

export const {
  setAiModel,
  updateAnswer,
  updateAnswerSteps,
  updateAnswerIdentification,
  updateSelectedQuestion,
  updateAdditionalInstruction,
  updateAnswerLoadingState,
  updateAnswerLoadingStartTimeMillis,
  updateShowMoreButtonClicked,
  updateAnswerAttempt,
  updateAnswerCardVisibility,
  updateIsAnswerLoaded,
  updateSuggestedFollowupQuestions,
  resetGPTSearchStates
} = answerGPT.actions;

export default answerGPT.reducer;
