import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import React, { useEffect } from "react";
import { useWhoami } from "../../clients/User/useWhoami";
import { fetchSelfServiceToken } from "../../pages/SettingsPage/Apps/SelfServiceConfiguration";

const F1_SCRIPT_SRC =
  "https://storage.googleapis.com/aptedge-self-service-customers/css/aptedge.js";
const F1_SCRIPT_STAGING_SRC =
  "https://storage.googleapis.com/aptedge-staging-self-service-bucket/css/aptedge.js";
const F1_SCRIPT_LOCAL_SRC =
  "http://localhost:5550/build-embedded-app/aptedge.js";

export const getScriptSrc = (instanceName: string): string => {
  if (instanceName?.includes("mock_data")) return F1_SCRIPT_LOCAL_SRC;
  if (instanceName?.includes("staging")) return F1_SCRIPT_STAGING_SRC;
  return F1_SCRIPT_SRC;
};

const F1AppInWebApp: React.FC = ({ children }) => {
  const { data: user } = useWhoami();
  const { flags } = useFlags();

  useEffect(() => {
    // don't run in unit tests
    if (
      process.env.JEST_WORKER_ID !== undefined ||
      process.env.NODE_ENV === "test"
    ) {
      return;
    }

    if (!user?.accountName) {
      console.log(
        "Account name not available; not injecting F1 wrapper into web app"
      );
      return;
    }

    if (!flags.f1AppInWebApp) {
      console.log("F1 app in web app disabled via FF");
      return;
    }

    console.log("Fetching self-service token...");
    const script = document.createElement("script");
    fetchSelfServiceToken()
      .then((response) => {
        if (!response) {
          console.log(
            "Self-service token not set; not injecting F1 wrapper into web app"
          );
          return;
        }

        console.log("Injecting F1 wrapper into web app...");
        const script = document.createElement("script");
        script.type = "module";
        script.async = true;
        script.src = getScriptSrc(user?.accountName);

        script.onload = () => {
          const configObject = {
            apiKey: response,
            instanceName: user?.accountName,
            user
          };

          window?.AptEdge && window.AptEdge(configObject);
        };
        document.body.appendChild(script);
      })
      .catch((reason) => {
        console.error(
          `Not injecting F1 wrapper into web app because the self-service token could not be retrieved: ${reason}`
        );
      });

    return () => {
      document.body.removeChild(script);
    };
  }, [flags.f1AppInWebApp, user]);

  return <>{children}</>;
};

export { F1AppInWebApp };
